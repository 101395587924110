<template>
  <div>
    <template v-if="news.items.length < 1">
      <NodateTempalte3
        class="mt-10"
        :text="$t('global.noData')"
      />
    </template>

    <template v-else>
      <v-col
        v-for="(item, index) in news.items"
        :key="item._id"
        cols="12"
        class="py-1"
      >
        <router-link
          :to="{
            name: 'news-item',
            params: {
              id: item._id,
            },
            query: {
              i: index + 1,
            },
          }"
          class="text-decoration-none"
        >
          <div class="news card1 rounded title--text subtitle-2 pa-4">
            <div class="news__header d-flex align-center">
              <span
                class="news__header__title"
                :class="[theme === 'light' ? 'primary--text' : 'secondary--text']"
              >
                {{
                  item.title.length > 12
                    ? item.title.slice(0, 10) + '...'
                    : item.title
                }}
              </span>

              <v-spacer />
              <span class="news__header__time caption hint--text">
                {{ item.start_datetime }}
              </span>
            </div>
            <div class="news__content caption mt-2 desc">
              {{ item.content }}
            </div>
          </div>
        </router-link>
      </v-col>
    </template>
  </div>
</template>

<script>
import announcementMixin from '../mixins/AnnouncementMixin'

export default {
  mixins: [announcementMixin],
}
</script>

<style scoped>
.desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
